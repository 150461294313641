import CookieHelper from "../Helpers/CookieHelper";
import {serializeForm} from "../Helpers/FormsHelper";
import {bodyOverflow} from "../Helpers/Body";

const selectors = {
    buySubscription: '.js--buy-subscription',
    form: 'form.cart',
    btnSubmit: '.single_add_to_cart_button',
    frequencyModalLabel: '.js--label',
    modal: '.js--modal-container',
    modalDescFreq: '.js--desc-freq',
    modalCartFreq: '.js--cart-freq',
    btnSubmitCart: '.btn-submit-cart'
}

const props = {
    single: 'single',
    subscription: 'sub',
    activeClass: 'active'
}

const cookie = new CookieHelper();

class Subscriptions {

    constructor() {
        this.buySubscription = document.querySelector(selectors.buySubscription);

        if (!this.buySubscription) {
            return;
        }

        this.form = document.querySelector(selectors.form);
        this.checkSubscriptions();
        this.activeLabelFrequency();
        this.updateFrequency(selectors.modalCartFreq);
    }


    /* check type product - single or subscription. */
    checkSubscriptions() {
        this.submit = document.querySelector(selectors.btnSubmit);

        if(!this.submit) {
            return;
        }

        this.submit.addEventListener('click', (evt) => {
            const serialize = serializeForm(this.form);

            if (serialize.type === props.subscription) {
                if (this.checkCookie(serialize.frequency)) {
                    evt.preventDefault();
                }
            }
        })
    }

    /* compare frequency value to an existing cookie */
    checkCookie(value) {
        if ((cookie.readCookie('woocommerce_items_in_cart') > 0) && (cookie.readCookie('frequency') !== false)) {
            const result = (cookie.readCookie('frequency') !== value);

            if (result) {
                this.openModalFrequency();
                this.updateFrequency(selectors.modalDescFreq);
            }
            return result;
        }
    }

    /* open modal */
    openModalFrequency() {
        const modal = this.form.querySelector(selectors.modal);
        if (modal) {
            modal.classList.add('show');
            bodyOverflow(true);
        }
    }


    /* update modal description frequency */
    updateFrequency(selectorToUpdate) {
        const frequencyChecked = document.querySelector('input[name="frequency"]:checked');
        if (frequencyChecked) {
            const currentFreqName = frequencyChecked.getAttribute('data-name');
            const fieldFreq = this.form.querySelector(selectorToUpdate);
            fieldFreq.innerHTML = currentFreqName;
        }
    }


    /* control .active class in modal frequency */
    activeLabelFrequency() {
        const nodeLabels = document.querySelectorAll(selectors.frequencyModalLabel);

        for (let i = 0; i < nodeLabels.length; i++) {
            nodeLabels[i].addEventListener('click', () => {

                /* remove all class active */
                for (let i = 0; i < nodeLabels.length; i++) {
                    nodeLabels[i].classList.remove(props.activeClass);
                }

                /* add active to clicked */
                nodeLabels[i].classList.add(props.activeClass);
            })
        }

    }

}

export default Subscriptions;
