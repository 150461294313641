const ACCORDION_PROPS = {
    itemSelector: '.js--accordion-item',
    headSelector: '.js--accordion-head',
    bodySelector: '.js--accordion-body',
    contentSelector: '.js--accordion-content',
    indexAttr: 'data-index',
    initialCollection: [],
    closeSibling: true,
};

class BaseClass {
    constructor () {
        this.dispatchHandlers = {};
        this.state = {};
    }

    /**
     * SET STATE
     * @param {Object} newState
     */
    setState(newState = {}) {
        this.state = {
            ...this.state,
            ...newState,
        };
    }

    /**
     * Event listener
     * @param {String} eventName
     * @param {Function} callback
     */
    on(eventName, callback) {
        const existsHandlerCollection = this.dispatchHandlers[eventName];

        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }

        return this;
    }

    /**
     * Event emiter
     * @param {String} eventName
     * @param {Object} data
     */
    emit(eventName, data = {}) {
        const handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}


class Accordion extends BaseClass {
    constructor (wrapperSelector = '.js--accordion', settings = {}){
        super();
        this.wrappers = document.querySelectorAll(wrapperSelector);
        this.settings = { ...ACCORDION_PROPS, ...settings };
        this.accordions = [];

        if (this.wrappers.length > 0){
            this.init();
        }
    }

    init () {
        if (this.wrappers.length > 1) {
            for (const wrapper of this.wrappers){
                const accordion = new AccordionItem(wrapper, this.settings);
                accordion.on('clickUpdate', (data) => this.emit('clickUpdate', data));
                accordion.on('update', (data) => this.emit('update', data));

                this.accordions.push(accordion);
            }
        } else {
            this.accordions = new AccordionItem(this.wrappers[0], this.settings);
            this.accordions.on('clickUpdate', (data) => this.emit('clickUpdate', data));
            this.accordions.on('update', (data) => this.emit('update', data));
        }
    }
}

class AccordionItem extends BaseClass {
    constructor(wrapper, settings) {
        super();
        this.wrapper = wrapper;
        this.settings = settings;
        this.items = this.wrapper.querySelectorAll(this.settings.itemSelector);

        this.initState();

        if (this.items.length > 0){
            this.events();
        }
    }

    events () {
        for (const item of this.items){
            const head = item.querySelector(this.settings.headSelector);

            if (head) {
                head.addEventListener('click', (event) => {
                    event.preventDefault();

                    const index = parseInt(item.getAttribute(this.settings.indexAttr));

                    if ((item.classList.contains('is-active') && (!item.classList.contains('js--accordion-not-close')))){
                        this.setState({ activeCollection: this.state.activeCollection.filter((item) => item !== index) });
                    } else {
                        if (this.settings.closeSibling){
                            this.setState({ activeCollection: [index] });
                        } else {
                            this.setState({ activeCollection: [...this.state.activeCollection, index] });
                        }
                    }

                    this.updateAccordion(true);
                });
            }
        }

        window.addEventListener('resize', this.resize.bind(this));
    }

    setActiveIndex(index) {
        const parsedIndex = parseInt(index);

        if (!this.state.activeCollection.includes(parsedIndex)) {
            if (this.settings.closeSibling){
                this.setState({ activeCollection: [parsedIndex] });
            } else {
                this.setState({ activeCollection: [...this.state.activeCollection, parsedIndex] });
            }
            this.updateAccordion();
        }
    }

    unsetActiveIndex(index) {
        const parsedIndex = parseInt(index);

        if (this.state.activeCollection.includes(parsedIndex)) {
            this.setState({ activeCollection: this.state.activeCollection.filter((item) => item !== parsedIndex) });
            this.updateAccordion();
        }
    }

    updateAccordion(isClickUpdate) {
        for (const item of this.items) {
            const index = parseInt(item.getAttribute(this.settings.indexAttr));

            if (this.state.activeCollection.includes(index)) {
                this.show(item);
            } else {
                this.hide(item);
            }
        }

        this.emit(isClickUpdate ? 'clickUpdate' : 'update', { state: this.state });
    }

    /** HIDE BODY */
    hide (item) {
        item.classList.remove('is-active');
        item.querySelector(this.settings.bodySelector).style.height = null;
    }

    /** SHOW BODY */
    show (item) {
        item.classList.add('is-active');
        item.querySelector(this.settings.bodySelector).style.height = `${item.querySelector(this.settings.contentSelector).offsetHeight}px`;
    }

    /** RESIZE ITEM BODY */
    resize() {
        if (this.state.activeCollection.length > 0){
            for (const item of this.items) {
                const index = parseInt(item.getAttribute(this.settings.indexAttr));

                if (this.state.activeCollection.includes(index)) {
                    item.querySelector(this.settings.bodySelector).style.height = `${item.querySelector(this.settings.contentSelector).offsetHeight}px`;
                }
            }
        }
    }

    initState() {
        this.state = {
            activeCollection: this.settings.initialCollection,
        };

        if (this.settings.initialCollection.length > 0) {
            this.updateAccordion();
        }
    }
}

export default Accordion;
