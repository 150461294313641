const selectors = {
	notificationsErrorsWrapper: '.woocommerce-NoticeGroup-checkout',
};

const props = {
	singleError: 'error',
	classInvalid: 'invalid',
}

class Checkout {
	constructor() {
		this.syncShippingMethod();
		this.disableCheckboxFakturownia();
	}

	/**
	 * Validation
	 */
	validationFields() {

		this.removeAllInlineErrors();

		this.notificationWrapper = document.querySelector( selectors.notificationsErrorsWrapper );

		if ( ! this.notificationWrapper ) {
			return;
		}

		const errors = this.notificationWrapper.querySelectorAll( '[data-id]' );

		for ( let i = 0; i <= errors.length; i++ ) {

			if ( errors[ i ] ) {
				const ID = errors[ i ].getAttribute( 'data-id' );
				const singleField = document.querySelector( `#${ ID }` );

				singleField.closest( '.single-field' ).classList.add( 'invalid' );
				singleField.insertAdjacentHTML( 'afterend', `<span class="${ props.singleError }">${ errors[ i ].textContent }</span>` );
				trigger.PubSub.global.emit( 'invalidFields' );
			}
		}
	}

	/**
	 * Remove Inline Errors if exist
	 */
	removeAllInlineErrors() {
		const insertedErrors = document.querySelectorAll( `.${ props.singleError }` );
		for ( let i = 0; i < insertedErrors.length; i++ ) {
			insertedErrors[ i ].parentNode.removeChild( insertedErrors[ i ] );
		}

		const invalid = document.querySelectorAll( `.${ props.classInvalid }` );
		for ( let i = 0; i < invalid.length; i++ ) {
			invalid[ i ].classList.remove( props.classInvalid );
		}
	}

	verifyEmptyFields() {
		const formCheckout = document.querySelector( '.payment_method_payu_recurring' );

		if ( ! formCheckout ) {
			return;
		}

		function verificationFields() {
			const btnOrder = jQuery( '#place_order' );
			const fields = jQuery( '.section-billing input[required]' ).add( '.term-require' );
			let okToEnable = true;

			fields.each( function ( i, field ) {

				if ( field.type === "text" && field.value === "" ) {
					// if incorrect at least once, turn the flag to false
					okToEnable = false;
					return false;
				}

				if ( field.type === "checkbox" && ! field.checked ) {
					okToEnable = false;
					return false;
				}

			} );

			// After the loop, act based on the flag
			if ( okToEnable && jQuery( "#term" ).is( ':checked' ) ) {
				btnOrder.prop( 'disabled', false );
			} else {
				btnOrder.prop( 'disabled', true );
			}
		}

		verificationFields();

		jQuery( 'input' ).on( 'change', () => {
			verificationFields();
		} );

	}

	syncShippingMethod() {
		jQuery( document.body ).on( 'payment_method_selected', () => {
			const value = jQuery( 'input[name="shipping_method[0]"]:checked' ).val();
			jQuery( '.shipping_method' ).val( value );
		} );

		jQuery( document ).on( 'change', '.shipping_method', function () {
			const value = jQuery( this ).val();
			jQuery( '.shipping_method' ).val( value );
		} );
	}

	disableCheckboxFakturownia() {
		const fakturowniaCheckbox = document.getElementById( 'billing_faktura' );
		if ( fakturowniaCheckbox ) {
			fakturowniaCheckbox.disabled = true;
		}
	}
}

export default Checkout;
