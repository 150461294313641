import {bodyOverflow} from "../Helpers/Body";

const selectors = {
    buttonToOpenModalClassName : 'js--open-modal',
    buttonToCloseModalClassName : 'js--close-modal',
    modalClassName : 'js--modal-container',
    body : 'body'
}

class Modal {

    constructor(){
        this.openModal();
        this.closeModal();
    }


    openModal() {

        document.addEventListener('click', (evt) => {
            if (evt.target.classList.contains(selectors.buttonToOpenModalClassName)) {
                evt.preventDefault();
                const target = evt.target.getAttribute('data-target');
                if(target){
                    document.querySelector(`#${target}`).classList.add('show');
                    bodyOverflow(true);
                }
            }
        })
    }

    closeModal(){
        document.addEventListener('click', (evt) => {
            const closeButton = evt.target.classList.contains(selectors.buttonToCloseModalClassName);

            if (closeButton) {
                evt.preventDefault();

                const modal = evt.target.closest(`.${selectors.modalClassName}`);
                if (modal) {
                    modal.classList.remove('show');
                    bodyOverflow(false);
                }
            }
        })

        document.addEventListener('click', (evt) => {
            const closeButton = evt.target.classList.contains(selectors.modalClassName);

            if (closeButton) {
                evt.preventDefault();
                evt.target.classList.remove('show');
                bodyOverflow(false);
            }
        })
    }


}

export default Modal;
