import Swiper from "swiper";

const selectors = {
    instagramWall: 'instagram-wall',
    initSlider: '.js--slider-instagram'
}

class InstagramWall {
    constructor() {
        this.instagram = document.querySelector(selectors.initSlider);

        if(!this.instagram){
            return;
        }
		this.initSlider();
    }

    initSlider(){
        const mySwiper = new Swiper(selectors.initSlider, {
            slidesPerView: 1.3,
            centeredSlides: true,
            spaceBetween: 25,
            freeMode: true,
            speed: 700,
            autoplay: {
                delay: 4000,
            },
            pagination: {
                el: '.slider-pagination-instagram',
                clickable: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    centeredSlides: false,
                },
                991: {
                    slidesPerView: 3,
                    centeredSlides: false,
                },
                1200: {
                    slidesPerView: 4,
                    centeredSlides: false,
                },
            }
        });
    }

}

export default InstagramWall;
