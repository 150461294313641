const selectors = {
    buttonClose: 'js--notification-close',
    containerNotification: '.js--notification-container',
};

class Notifications {
    constructor() {
        this.removeNotification();
    }

    /**
     * Remove (close) notification woocommerce
     */
    removeNotification() {
        document.addEventListener('click', (evt) => {
            if (evt.target.classList.contains(selectors.buttonClose)) {
                evt.preventDefault();
                const notificationContainer = evt.target.closest(selectors.containerNotification);
                notificationContainer.parentNode.removeChild(notificationContainer);
            }
        })

    }

}

export default Notifications;
