const ENV = {
    requestTimeout: 5000,
    breakpoints: {
        'xs': 0,
        'xs-max': 575,
        'sm': 576,
        'sm-max': 767,
        'md': 768,
        'md-max': 991,
        'lg': 992,
        'lg-max': 1199,
        'xl': 1200
    },
};

export default ENV;