import {cookie} from "./Subscriptions";
import CookieHelper from "../Helpers/CookieHelper";
const cookieItems = new CookieHelper();

const selectors = {
    btnUpdate: '.js--update-cart',
    miniCartIcon: '.js--cart-items'
};

/**
 * Trigger Update Button
 */
export function autoUpdate() {
    const buttonUpdate = document.querySelector(selectors.btnUpdate);

    if (!buttonUpdate) {
        return;
    }

    buttonUpdate.disabled = false;
    jQuery(buttonUpdate).trigger("click");
}

/**
 * Mini Cart Info
 */
export function miniCartInfo() {
    const miniCart = document.querySelector(selectors.miniCartIcon);

    if(!miniCart){
        return;
    }

    const itemsInCart = cookieItems.readCookie('woocommerce_items_in_cart');

    if (itemsInCart > 0) {
        miniCart.classList.add('show');
        miniCart.innerHTML = itemsInCart;
    }
};
