import {getSiblings} from "../Helpers/FormsHelper";
import {autoUpdate} from "./Cart";

const selectors = {
    frequency: '.js--frequency',
    btnUpdate: '.js--update-cart',
    btnAddToCart: '.single_add_to_cart_button',
    pricePDP: '.js--price',
    priceDiscountPDP: '.js--discount',
};

class Frequency {
    constructor() {
        this.setFrequency();
    }


    /**
     * Set Frequency to subscriptions
     */
    setFrequency() {
        this.frequency = document.querySelector(selectors.frequency);

        if (!this.frequency) {
            return;
        }

        const labelsFreq = this.frequency.querySelectorAll('[name="frequency"]');
        for (let i = 0; i < labelsFreq.length; i++) {
            labelsFreq[i].addEventListener('change', () => {
                autoUpdate();
                this.addActiveClassToLabel(labelsFreq[i].id);
                this.updatePriceOnPDP(labelsFreq[i].getAttribute('data-price'));
                this.updateDiscountOnPDP(labelsFreq[i].getAttribute('data-discount'));
            })
        }
        this.activeChecked();
    }

    /**
     * update price on pdp if exist
     */
    updatePriceOnPDP(price) {
        const pricePDP = document.querySelector(selectors.pricePDP);
        if (pricePDP) {
            pricePDP.querySelector('bdi').innerHTML = price;
        }
    }

    updateDiscountOnPDP(discount){
        const priceDiscountPDP = document.querySelector(selectors.priceDiscountPDP);

        if(priceDiscountPDP){
            priceDiscountPDP.innerHTML = `${discount}%`;
        }
    }

    /**
     * Add active class to label for checked element
     */
    addActiveClassToLabel(item) {
        const label = document.querySelectorAll("label[for='" + item + "']");

        if (!label) {
            return;
        }

        for (let i = 0; i < label.length; i++) {

            // for siblings remove active class.
            const siblings = getSiblings(label[i]);
            for (let i = 0; i < siblings.length; i++) {
                siblings[i].classList.remove('active');
            }

            // add active class
            label[i].classList.add('active');
        }
    }

    /**
     * Add active class to label on load
     */
    activeChecked() {
        const checkedFrequency = this.frequency.querySelector("input[type='radio'][name='frequency']:checked");
        if (checkedFrequency) {
            this.addActiveClassToLabel(checkedFrequency.id);
        }
    }

}

export default Frequency;
