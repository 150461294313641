import Cookie from '../Helpers/CookieHelper';

const cookieGDPR = new Cookie();

class GDPR {
    constructor(){
        this.init();
    }

    init() {
        this.notice = document.querySelector('.js--cookies-notice');
        this.close = document.querySelector('.js--cookies-button');
        this.body = document.querySelector('body');

        if(!this.notice) {
            return;
        }

        this.close.addEventListener('click', () => {
            this.notice.style.display = 'none';
            this.saveCookie();
        });
        this.checkCookies();
    }

    saveCookie() {
        cookieGDPR.createCookie('cookies', 'accept', 365, __jsVars.cookie.path, __jsVars.cookie.domain, __jsVars.cookie.secure);
    }

    checkCookies() {
        if (!(cookieGDPR.readCookie('cookies') === 'accept')) {
            this.notice.style.display = 'block';
        }
    }
}

export default GDPR;
