import {autoUpdate} from "./Cart";

const selectors = {
    plusQty: '.js--plus',
    minusQty: '.js--minus'
};

class Quantity {
    constructor() {
        this.calcQuantity();
    }

    /**
     * Custom +/- Quantity
     */
    calcQuantity() {
        this.plusQty = document.querySelectorAll(selectors.plusQty);
        this.minusQty = document.querySelectorAll(selectors.minusQty);

        if (!this.plusQty.length > 0 && !this.minusQty.length > 0) {
            return;
        }

        for (let i = 0; i < this.plusQty.length; i++) {
            this.plusQty[i].addEventListener('click', (evt) => {
                evt.preventDefault();

                const field = this.plusQty[i].closest('.quantity').querySelector('input[type="number"]');
                const currentVal = parseInt(field.value);

                if (!isNaN(currentVal)) {
                    field.value = currentVal + 1;
                    autoUpdate();
                }
            })
        }


        for (let i = 0; i < this.minusQty.length; i++) {
            this.minusQty[i].addEventListener('click', (evt) => {
                evt.preventDefault();

                const field = this.minusQty[i].closest('.quantity').querySelector('input[type="number"]');
                const currentVal = parseInt(field.value);

                if (!isNaN(currentVal) && currentVal > 1) {
                    field.value = currentVal - 1;
                    autoUpdate();
                }

            })
        }


    }

}

export default Quantity;
