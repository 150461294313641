// core version + navigation, pagination modules:
import Swiper, { Pagination, Navigation, Autoplay} from 'swiper';

// configure Swiper to use modules
Swiper.use([Pagination, Navigation, Autoplay]);

const selectors = {
    sliderFeaturesPDP: '.js--slider-features',
    sliderProducts: '.js--slider-products',
    sliderOpinions: '.js--slider-opinions',
}

class Slider {
    constructor() {
        this.sliderFeatures();
        this.sliderProducts();
        this.sliderOpinions();
    }

    sliderOpinions(){
        const slider = document.querySelector(selectors.sliderOpinions);

        if (!slider) {
            return;
        }

        const mySwiper = new Swiper(selectors.sliderOpinions, {
            centeredSlides: true,
            slidesPerView: 1.1,
            loop: true,
            speed: 700,
            autoplay: {
                delay: 6000,
            },
            spaceBetween: 25,
            navigation: {
                nextEl: '.next-opinion',
                prevEl: '.prev-opinion',
            },
            breakpoints: {
                768: {
                    slidesPerView: 1.7,
                    spaceBetween: 40,
                },
            }
        });

    }

    sliderProducts(){
        const slider = document.querySelector(selectors.sliderProducts);

        if (!slider) {
            return;
        }

        const mySwiper = new Swiper(selectors.sliderProducts, {

            slidesPerView: 1.2,
            spaceBetween: 0,
            navigation: {
                nextEl: '.next-product',
                prevEl: '.prev-product',
            },
            pagination: {
                el: '.slider-pagination-products',
                clickable: true,
            },
            breakpoints: {
                575: {
                    slidesPerView: 1.7,
                    initialSlide: 0,
                    centeredSlides: false,
                    spaceBetween: 10,
                },
                991: {
                    slidesPerView: 2.5,
                    initialSlide: 0,
                    centeredSlides: false,
                    spaceBetween: 10,
                },
                1280: {
                    slidesPerView: 3.5,
                    initialSlide: 0,
                    centeredSlides: false,
                    spaceBetween: 10,
                },
                1700: {
                    slidesPerView: 4.5,
                    initialSlide: 0,
                    centeredSlides: false,
                    spaceBetween: 10,
                },
            }
        });

    }

    sliderFeatures() {
        const slider = document.querySelector(selectors.sliderFeaturesPDP);

        if (!slider) {
            return;
        }

        const breakpoint = window.matchMedia('(min-width:768px)');
        let mySwiper;
        const breakpointChecker = function () {
            if (breakpoint.matches === true) {
                if (mySwiper !== undefined) mySwiper.destroy(true, true);
                return;
            } else if (breakpoint.matches === false) {
                return enableSwiper();
            }
        };
        const enableSwiper = function () {
            mySwiper = new Swiper(selectors.sliderFeaturesPDP, {
                slidesPerView: 1.3,
                centeredSlides: true,
                spaceBetween: 20,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                breakpoints: {
                    400: {
                        slidesPerView: 1.8,
                        spaceBetween: 25,
                    },
                    640: {
                        slidesPerView: 2.4,
                        spaceBetween: 30,
                    },
                }
            });
        };
        breakpoint.addListener(breakpointChecker);
        breakpointChecker();

    }
}

export default Slider;
