import '../Helpers/Triggers';
/*
* Example Usage:
*
* <button class="js--collapse-button" data-collapse-id="target">Button</button>
	<div class="collapse js--collapse-body" data-collapse-id="target">
		<div class="js--collapse-content"> Body Content </div>
	</div>
* */


const selectors = {
    buttonSelector: '.js--collapse-button',
    bodySelector: '.js--collapse-body',
    contentSelector: '.js--collapse-content',
    collapseTargetId: 'data-collapse-id',
    collapsedClass: 'is-collapsed',
};

class Collapse {
    constructor() {
        this.collapsing();
    }

    collapsing() {

        /* on change (e.g. checkbox) */
        document.addEventListener('change', (evt) => {
            if (evt.target.classList.contains('js--collapse-change')) {
                this.togglePointedContainer(evt.target);
            }
        })

        /* on click (e.g. buttons) */
        document.addEventListener('click', (evt) => {
            if (evt.target.classList.contains('js--collapse-button')) {
                evt.preventDefault();
                this.togglePointedContainer(evt.target);
            }
        })

        window.addEventListener('resize', this.resizeCalc.bind(this));

        trigger.PubSub.global.on('invalidFields', () => {
            this.resizeCalc();
        });

    }

    /**
     * Toggle
     * @param {HTMLElement} target
     */
    togglePointedContainer(target){
        if (target.hasAttribute(selectors.collapseTargetId)) {
            const collapseId = target.getAttribute(selectors.collapseTargetId);
            const collapseButtons = document.querySelectorAll(`${selectors.buttonSelector}[${selectors.collapseTargetId}="${collapseId}"]`);
            const collapseBody = document.querySelector(`${selectors.bodySelector}[${selectors.collapseTargetId}="${collapseId}"]`);

            if (collapseButtons && collapseBody) {
                this.toggle(collapseButtons, collapseBody);
            }
        }
    }

    /**
     * Toggle
     * @param {NodeList} buttons
     * @param {HTMLElement} body
     */
    toggle(buttons, body) {
        if (body.classList.contains(selectors.collapsedClass)) {
            this.hide(buttons, body);
        } else {
            this.show(buttons, body);
        }
    }

    /**
     * Hide
     * @param {NodeList} buttons
     * @param {HTMLElement} body
     */
    hide(buttons, body) {
        for (let i = 0; i < buttons.length; i++) {
            buttons[i].classList.remove(selectors.collapsedClass);
        }
        body.classList.remove(selectors.collapsedClass);
        body.style.height = null;
    }

    /**
     * Show
     * @param {NodeList} buttons
     * @param {HTMLElement} body
     */
    show(buttons, body) {
        for (let i = 0; i < buttons.length; i++) {
            buttons[i].classList.add(selectors.collapsedClass);
        }
        body.classList.add(selectors.collapsedClass);
        body.style.height = `${body.querySelector(selectors.contentSelector).scrollHeight}px`;
    }

    /**
     * Calc on window resize
     */
    resizeCalc() {
        const collapsed = document.querySelectorAll(`${selectors.bodySelector}.${selectors.collapsedClass}`);

        if (collapsed.length > 0) {
            for (const body of collapsed) {
                body.style.height = `${body.querySelector(selectors.contentSelector).scrollHeight}px`;
            }
        }
    }
}

export default Collapse;
