const selectors = {
    header: '.js--header',
    hamburger: '.js--h-button',
    menu: '.js--h-menu',
    body: 'body',
    arrow: 'js--arrow-submenu',
    itemsSubmenu: '.header .menu-item-has-children > a',

};

class Header {
    constructor() {
        this.header = document.querySelector(selectors.header);
        this.hamburger = document.querySelector(selectors.hamburger);
        this.menu = document.querySelector(selectors.menu);
        this.body = document.querySelector(selectors.body);

        if (!this.header) {
            return;
        }

        this.toggleMobileMenu();
        this.childrenMenu();
    }


    toggleMobileMenu() {
        this.hamburger.addEventListener('click', () => {
            this.hamburger.classList.toggle('open');
            this.header.classList.toggle('active');
            this.menu.classList.toggle('show');
            this.body.classList.toggle('body-overflow');
        });
    }

    childrenMenu() {
        const itemsSubmenu = document.querySelectorAll(selectors.itemsSubmenu)

        if (itemsSubmenu.length <= 0) {
            return;
        }

        for (let i = 0; i < itemsSubmenu.length; i++) {
            itemsSubmenu[i].insertAdjacentHTML('afterend', '<button type="button" class="btn-reset arrow-submenu js--arrow-submenu"></button>');
        }

        /* toggle submenu */
        document.addEventListener('click', (evt) => {
            if (evt.target.classList.contains('js--arrow-submenu')) {
                evt.preventDefault();
                evt.target.parentNode.querySelector('.sub-menu').classList.toggle('active');
            }
        })


    }
}

export default Header;
