trigger = {};
((PubSub) => {
    const Create = class Create {
        constructor(moduleName) {
            this.moduleName = moduleName;
            this.events = {};
        }
        on(eventName, callback) {
            this.events[eventName] = this.events[eventName] || [];
            this.events[eventName].push(callback);
        }
        emit(eventName, data) {
            if (this.events[eventName]) {
                this.events[eventName].forEach(callback => {
                    callback(data);
                });
            }
        }
    };
    PubSub.Create = Create;
    PubSub.global = new Create('Global');
})(trigger.PubSub = trigger.PubSub || {});
