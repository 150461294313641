/** HELPERS */
import './Helpers/Polyfill';

/** COMMON */
import Header from './Common/Header';
import GDPR from './Common/GDPR';
import Quantity from './Common/Quantity';
import Frequency from './Common/Frequency';
import Checkout from './Common/Checkout';
import Notifications from './Common/Notifications';
import Subscriptions from './Common/Subscriptions';
import Modal from './Common/Modal';
import Slider from './Common/Sliders';
import InstagramWall from "./Common/Instagram";
import Animations from './Common/Animations';
import SearchBar from './Common/SearchBar';
import {miniCartInfo} from "./Common/Cart";
import SocialMedia from "./Helpers/SocialMedia";
import Collapse from "./Helpers/Collapse";
import Accordion from "./Helpers/Accordion";

/** STYLES */
import '../scss/Index.scss';

class IndexController {
    constructor() {
        this.header = new Header();
        this.gdpr = new GDPR();
        this.quantity = new Quantity();
        this.frequency = new Frequency();
        this.checkout = new Checkout();
        this.notifications = new Notifications();
        this.subscriptions = new Subscriptions();
        this.searchbar = new SearchBar();
        this.collapse = new Collapse();
        this.modal = new Modal();
        this.slider = new Slider();
        this.social = new SocialMedia();
        this.instagram = new InstagramWall();
        this.animations = new Animations();
        this.accordion = new Accordion('.js--section-accordion');
        this.bindEvents();
        miniCartInfo();
    }

    /**
     * Bind to default WooCommerce Events
     */
    bindEvents() {
        jQuery(document.body).on('updated_cart_totals', () => {
            this.quantity.calcQuantity();
            this.frequency.setFrequency();
            miniCartInfo();
        });
        jQuery(document.body).on('checkout_error', () => {
            this.checkout.validationFields();
        });
        jQuery(document.body).on('updated_checkout', () => {
            this.checkout.verifyEmptyFields();
        });

    }

}


// Loader babel polyfill only if needed
const BROWSER_UNSUPPORT_FEATURES = !window.Promise || !window.fetch || !window.Symbol || !Object.assign;

if (BROWSER_UNSUPPORT_FEATURES && typeof __jsVars !== 'undefined' && typeof __jsVars.dist !== 'undefined' && typeof __jsVars.dist.babelPolyfill !== 'undefined') {
    const js = document.createElement('script');
    js.src = __jsVars.dist.babelPolyfill;
    js.onload = function () {
        new IndexController();
    };
    js.onerror = function () {
        new IndexController();
        new Error('Failed to load script ' + __jsVars.dist.babelPolyfill);
    };
    document.head.appendChild(js);
} else {
    new IndexController();
}
