import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ENV from '../Config/Env';

gsap.registerPlugin(ScrollTrigger);

class Animations {
    constructor() {
        this.animationDecoration();
    }

    animationDecoration() {
        this.tabletDesktop = `(min-width: ${ENV.breakpoints['md']}px)`;
        this.DesktopXL = `(min-width: ${ENV.breakpoints['xl']}px)`;
        this.plant1Decor();
        this.plant2Decor();
        this.plant3Decor();
        this.plant6Decor();
        this.plant7Decor();
        this.plant8Decor();
    }

    /* parallax decor plant 1 */
    plant1Decor() {
        const decorPlant1 = document.querySelector('.decor-plant-1');

        if (!decorPlant1) {
            return;
        }

        ScrollTrigger.matchMedia({
            [this.tabletDesktop]: function () {
                gsap.to(decorPlant1, {
                    yPercent: 25,
                    ease: "none",
                    scrollTrigger: {
                        trigger: decorPlant1,
                        scrub: true,
                        markers: false
                    },
                });
            }

            ,
        })
        ;
    }

    /* parallax decor plant 2 */
    plant2Decor() {
        const decorPlant2 = document.querySelector('.decor-plant-2');

        if (!decorPlant2) {
            return;
        }

        ScrollTrigger.matchMedia({
            [this.tabletDesktop]: function () {
                gsap.to(decorPlant2, {
                    yPercent: 25,
                    ease: "none",
                    scrollTrigger: {
                        trigger: decorPlant2,
                        scrub: true,
                        markers: false
                    },
                });
            },
        });
    }

    /* parallax decor plant 3 */
    plant3Decor() {
        const decorPlant3 = document.querySelector('.decor-plant-3');

        if (!decorPlant3) {
            return;
        }

        ScrollTrigger.matchMedia({
            [this.DesktopXL]: function () {
                gsap.to(decorPlant3, {
                    yPercent: 45,
                    ease: "none",
                    scrollTrigger: {
                        trigger: decorPlant3,
                        scrub: true,
                        markers: false
                    },
                });
            },
        });

    }

    /* parallax decor plant 6 */
    plant6Decor() {
        const decorPlant6 = document.querySelector('.decor-plant-6');

        if (!decorPlant6) {
            return;
        }

        ScrollTrigger.matchMedia({
            [this.tabletDesktop]: function () {
                gsap.to(decorPlant6, {
                    yPercent: 25,
                    ease: "none",
                    scrollTrigger: {
                        trigger: decorPlant6,
                        scrub: true,
                        markers: false
                    },
                });
            }
        });
    }

    /* parallax decor plant 7 */
    plant7Decor() {
        const decorPlant7 = document.querySelector('.decor-plant-7');

        if (!decorPlant7) {
            return;
        }

        ScrollTrigger.matchMedia({
            [this.tabletDesktop]: function () {
                gsap.to(decorPlant7, {
                    yPercent: 15,
                    ease: "none",
                    scrollTrigger: {
                        trigger: decorPlant7,
                        scrub: true,
                        markers: false
                    },
                });
            }

            ,
        })
        ;
    }

    /* parallax decor plant 8 */
    plant8Decor() {
        const decorPlant8 = document.querySelector('.decor-plant-8');

        if (!decorPlant8) {
            return;
        }

        ScrollTrigger.matchMedia({
            [this.tabletDesktop]: function () {
                gsap.to(decorPlant8, {
                    yPercent: 25,
                    ease: "none",
                    scrollTrigger: {
                        trigger: decorPlant8,
                        scrub: true,
                        markers: false
                    },
                });
            }
        });
    }
}

export default Animations;
