import { getScrollPosition } from "./Scroll";

const is_iOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

export function setBodyFixed() {
    if (is_iOS()) {
        const position = getScrollPosition().top;
        document.body.classList.add('ios-body-fixed-overflow');
        document.body.style.top = `-${position}px`;
        document.body.setAttribute('data-scroll-position', position);
    }
}

export function unsetBodyFixed() {
    if (is_iOS() && document.body.classList.contains('ios-body-fixed-overflow') && document.body.hasAttribute('data-scroll-position')) {
        const position = parseInt(document.body.getAttribute('data-scroll-position'));
        document.body.removeAttribute('data-scroll-position');
        document.body.classList.remove('ios-body-fixed-overflow');
        document.body.style.top = `auto`;
        window.scroll(0, position);
    }
}

export function bodyOverflow(overflow = false) {
    if (overflow){
        const scrollBarWidth = window.innerWidth - document.body.offsetWidth;

        document.body.style.marginRight = `${scrollBarWidth}px`;
        document.body.classList.add('body-overflow');
    } else{
        document.body.classList.remove('body-overflow');
        document.body.style.marginRight = `0`;
    }
}
