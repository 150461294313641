const selectors = {
    searchBar: '.js--search-bar',
    searchToggle: '.js--search-toggle',
};

class SearchBar {
    constructor() {
        this.searchBar = document.querySelector(selectors.searchBar);
        this.searchToggle = document.querySelector(selectors.searchToggle);

        if (!this.searchBar) {
            return;
        }

        this.toggleSearchBar();
    }


    toggleSearchBar() {
        this.searchToggle.addEventListener('click', () => {
            this.searchBar.classList.toggle('open');
        });
    }
}

export default SearchBar;
